<template>
    <section id="features" class="feature-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Storium loob head tingimused</h2>
                        <p class="text-muted para-desc mb-0 mx-auto">Läbimõledud lahendus, kus tugisüsteemid tagavad, et toodete haldamine ning tellimuste läbiviimine on lihtne ja kiire ning ostude sooritamine sinu kliendile mugav.</p>
                    </div>
                </div>
            </div>

            <div class="row " style="margin-top: 25px;">
                <div class="col-lg-8 col-md-12" id="halfWidth" >
                    <div class="">
                        <div class="col-md-6 col-12" style="max-width: 100%; padding: 0;">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-email p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Aja asju läbi e-posti</h5>
                                    <p>Storiumi süsteem kannab õigel hetkel Sinu e-posti tähelepanu vajava teabe.
                                      <br><br>
                                      Olgu selleks uus tellimus või otsakorral toode, süsteem kannab kõik vajamineva otse teie postkasti, kus teil on võimalik 1-2 klikiga otsus langetada.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12" style="max-width: 100%;padding: 0;">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-receipt p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Tellimuste haldus</h5>
                                    <p>Iga uus tellimus saadetakse emailile koos arve ja tarnelehega, läbi selle kirja on sul võimalik alati siseneda antud tellimuse haldusesse. Igat tellimust on võimalik tagasi lükata, tühistada, raha/kaup tagastada, esitada juurdemakse päringuid jms.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12" style="max-width: 100%;padding: 0;">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-map-alt p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Tarneprofiil</h5>
                                    <p>Tarneprofiil lubab sul ise seadistada milliseid meetodeid kasutad, palju on maksumus, mis miinimum tellimuse väärtus jne.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12" style="max-width: 100%;padding: 0;">
                            <div class="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                                <span class="ti-package p-3 mr-4 mt-1 rounded-circle float-left"></span>
                                <div class="features-single-content d-block overflow-hidden">
                                    <h5 class="mb-2">Tooted ja laoseis</h5>
                                    <p>Tooteid on võimalik lisada hulgi ja kiiresti. Laoseisu, staatust, hinnastust ja muid andmetükke on võimalik korraga muuta tabelist. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="halfWidthBottom">
                  <notifications style="z-index: 10; position: relative;" />
                  <br>
                  <orderTable />
                  <br>
                  <!-- <shippingMethodCard /> -->
                  
                    <svg class="dashedPosition" x="0px" y="0px" width="312px" height="130px">
                        <path
                            class="dashed1"
                            fill="none"
                            stroke="rgb(95, 93, 93)"
                            stroke-width="1"
                            stroke-dasharray="1300"
                            stroke-dashoffset="0"
                            d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                        ></path>
                        <path
                            class="dashed2"
                            fill="none"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-dasharray="6"
                            stroke-dashoffset="1300"
                            d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                        ></path>
                    </svg>
                    <svg id="dashedRemoved" x="0px" y="0px" style="position: absolute; margin-top: -175px; margin-left: -300px;" width="312px" height="130px">
                        <path
                            class="dashed1"
                            fill="none"
                            stroke="rgb(95, 93, 93)"
                            stroke-width="1"
                            stroke-dasharray="1300"
                            stroke-dashoffset="0"
                            d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                        <path
                            class="dashed2"
                            fill="none"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-dasharray="6"
                            stroke-dashoffset="1300"
                            d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                    </svg>
                                
                  <div style="position: relative;;display: flex; justify-content: space-between; width: 100%; margin-top: 100px">
                    <listingCard :title="'Soojad saiad otse ahjust - pakendatud (300g/päts)'" :image="'productImage3'" class="productOne" :price="'24,50'" :shop="'Kabli pagar'" />
                    <listingCard :title="'Autentne Mesi 500g'" :image="'productImage4'" :price="'24,50'"  class="productTwo" :shop="'Bienen Konig'" />
                    <img 
                      class="backgroundIll"
                      :src="require('../../assets/storium/shapes/flower.svg')"
                      alt=""
                    >
                  </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import notifications from '../../components/storium/notifications.vue';
import orderTable from '../../components/storium/orderTable.vue';
// import shippingMethodCard from '../../components/storium/shippingMethodCard.vue';
import listingCard from  '../../components/storium/listingCard.vue';
    export default {
        name: 'Features',
        components: {
          notifications,
          orderTable,
          // shippingMethodCard,
          listingCard
        },
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style>
.dashedPosition {
    position: absolute; margin-top: -35px; margin-left: 70px;
}
#halfWidth {
    max-width: 50%;
}
#halfWidthBottom {
    max-width: 50%;
}
.backgroundIll {
    position: absolute; bottom: -20px; right: -90px; opacity: .1;
}
@media (max-width: 1200px) {
    .productOne {
        display: none;
    }
    #dashedRemoved {
        display: none;
    }
    .productTwo {
        margin-left: auto;
    }
    .dashedPosition {
        margin-left: 30px;
    }
}
@media (max-width: 767px) {
    #features .row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    #halfWidth {
        max-width: 100%;
        margin-bottom: 20px;
    }
    #halfWidthBottom {
        max-width: 100%;
    }
    /* .productOne {
        display: initial;
    } */
    .dashedPosition {
        margin-left: 70px;
    }
    .productTwo {
        margin-left: auto;
        margin-right: auto;
    }
    .backgroundIll {
        position: absolute; bottom: -20px; right: 0px; opacity: .1;
    }
}

@media (max-width: 575px) {
    #halfWidthBottom {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (max-width: 500px) {
    .dashedPosition {
        margin-left: 0px;
    }
    .col-md-12 {
        padding: 0;
    }
}
</style>