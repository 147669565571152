<template>
    <section id="contact" style="position: relative;" class="contact-us-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
      <div class="animated-shape-wrap">
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item" style="opacity: 0;"></div>
      <div class="animated-shape-item"></div>
      <div class="animated-shape-item"></div>
    </div>
      <img src="../../assets/img/combined-shape-2.svg"  alt="s" style="position: absolute;  bottom: 0;">
      <div class="container" >
            
            <div class="row justify-content-around">
                <div class="col-12 pb-3 message-box"
                     v-bind:class="{ 'd-none': !isSuccess && !hasError, 'd-block': isSuccess || hasError }">
                    <div class="alert" v-bind:class="{ 'alert-danger': hasError, 'alert-success': isSuccess}">{{ alertText }}</div>
                </div>
                <div class="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0" style="position: relative;">
                <svg class="removeSvg" width="355" height="299" style="position: absolute; right: 0; left: 0; margin: auto; top: -253px; opacity: .5;" viewBox="0 0 485 299" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 298C1 298 235.128 -370.25 484 298" stroke="#848484" stroke-dasharray="6 6"/>
                </svg>
                  <div class="contact-us-form gray-light-bg  p-5" style="position: relative;z-index: 1; border-radius: 30px; box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);">
                        <h4>Saada teade ja me võtame Sinuga ühendust</h4>
                        <form v-on:submit="submit" id="contactForm" class="contact-us-form">
                            <div class="form-row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            placeholder="Nimi või ettevõte"
                                            v-model="name"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="phone"
                                            placeholder="Telefoni number"
                                            required="required"
                                            v-model="phone"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            class="form-control"
                                            name="email"
                                            placeholder="E-posti aadress"
                                            required="required"
                                            v-model="email"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea
                                            name="message"
                                            id="message"
                                            class="form-control"
                                            rows="7"
                                            cols="25"
                                            placeholder="Tahad midagi veel lisada?"
                                            v-model="message"
                                        ></textarea>
                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <button @click="postShopInquiry" class="btn btn-brand-02" id="btnContactUs">Saada teavitus</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                  <div class="contact-us-content">
                      <h2>Soovid endale Storiumi e-poodi või tekkis küsimusi?</h2>
                      <p  class="lead">Kirjuta meile või helista, pakume tasuta konsultatsiooni kuidas Storium saab Sinu ärile kasulik olla.</p>
                      <a href="#" class="btn btn-outline-brand-01 align-items-center">
                          Vaata demo
                          <span class="ti-arrow-right pl-2"></span>
                      </a>
                      <img src="../../assets/img/shape-1.svg" alt="group shape" width="100" style="position: absolute; right: 10px; opacity: .3;">
                      <hr class="my-5" />
                      <ul class="contact-info-list" style="position: relative;">
                          <li class="d-flex pb-3">
                              <div class="contact-icon mr-3">
                                  <span class="fas fa-location-arrow color-primary rounded-circle p-3"></span>
                              </div>
                              <div class="contact-text">
                                  <h5 class="mb-1">OÜ Storium</h5>
                                  <p>Savi 3 86501, Pärnumaa</p>
                              </div>
                          </li>
                          <li class="d-flex pb-3">
                              <div class="contact-icon mr-3">
                                  <span class="fas fa-envelope color-primary rounded-circle p-3"></span>
                              </div>
                              <div class="contact-text">
                                  <h5 class="mb-1">E-posti aadress</h5>
                                  <p>info@storium.ee</p>
                              </div>
                          </li>
                          <li class="d-flex pb-3">
                              <div class="contact-icon mr-3">
                                  <span class="fas fa-phone color-primary rounded-circle p-3"></span>
                              </div>
                              <div class="contact-text">
                                  <h5 class="mb-1">Telefoni number</h5>
                                  <p>+372 5918 6042</p>
                              </div>
                          </li>
                      </ul>
                    </div>
                  </div>
              </div>
          </div>
    </section>
</template>


<script>
import core from '@/api/index'
    export default {
        name: 'Contact',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                email: null,
                message: null,
                name: null,
                phone: null,
                alertText: '',
                hasError: false,
                isSuccess: false
            };

        },
        methods: {
            postShopInquiry() {
                let sendBody = {
                        firstName: null,
                        lastName: null,
                        email: this.email,
                        phone: {
                            number: this.phone,
                            countryCode: 'EE'
                        },
                        homePageUrl: null,
                        bizName: this.name,
                        message: this.message
                    }

                core.shopInquiryApi.postShopInquiry({
                    body: JSON.stringify(sendBody)
                })
                .then((res) => {
                    if(res.status == 'success') {
                        this.name = null
                        this.message = null
                        this.email = null
                        this.phone = null
                        this.alertText = 'Teavitus edukalt saadetud!'
                        this.hasError = false
                        this.isSuccess = true
                    } else {
                        this.isSuccess = false;
                        this.hasError = true;
                        if(res.message == 'Phone number must be of type string.') {
                            this.alertText = 'Sisesta valiidne telefoninumber!'
                        }
                        if(res.message == 'The string supplied did not seem to be a phone number') {
                            this.alertText = 'Sisesta valiidne telefoninumber!'
                        }
                        if(res.message == 'Not a valid phone number.') {
                            this.alertText = 'Sisesta valiidne telefoninumber!'
                        }
                        if(res.message == 'User must have a valid email.') {
                            this.alertText = 'Sisesta valiidne e-posti aadress!'
                        }
                    }
                })
            }
        },
        mounted() {
            this.name = null
            this.message = null
            this.email = null
            this.phone = null
            this.alertText = '';
            this.hasError = false;
            this.isSuccess = false;
        }
    }
</script>
<style>
@media (max-width: 430px) {
    .removeSvg {
        display: none;
    }
    .p-5 {
        padding: 2rem !important;
    }
}
</style>
