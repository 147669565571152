<template>
    <div  class="overflow-hidden" style="position: relative;z-index: 1;">
        <section id="about" class="position-relative overflow-hidden feature-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
            <div class="container" style="padding-bottom: 80px;">
                <div class="row align-items-center justify-content-between">
                    <div class="col-md-12 col-lg-6" style="position: relative; z-index: 10;">
                        <div class="feature-contents section-heading" style="margin-top: 20px">
                            <h2 style="color: #545B63; margin-bottom: 10px;">Tutvu lahendusega</h2>
                            <p style="color: #545B63; margin-bottom: 35px;">Tutvumiseks on kaks demo: Poe avalik pool, kus on näidatud kuidas klient näeb tootekataloogi, ostukorvi, tellimisprotsessi ja muud. Teine demo on halduskeskkonnast, kus saate tutvuda enda poe, tellimuste ja toodete haldamisega.</p>
                            <!-- <img 
                              :src="require('../../assets/storium/storium.svg')"
                              alt="logo"
                              width="150px"
                              class="img-fluid"
                              style="margin-top: -15px; opacity: .31; margin-bottom: 20px;"
                            /> -->
                            <div style="display: flex">
                                <i class="pi pi-chart-line" style="margin-top: 1px;color: #F6A029"></i><h5 style="color: #F6A029; margin-left: 5px;">Halduskeskkond</h5>
                            </div>
                            <p>
                                Tutvu kuidas hakkad haldama enda tooteid ja täitma tellimusi. Uute toodete lisamisest, tarnemeetodite sätestamiseni ja lõpetades tellimuste haldamisega. Storiumi e-poe halduskkeskond tagab kõik vajaminevad tööriistad, suutes samal ajal jääda kasutajasõbralikuks.
                            </p>
                            <br>
                            <div style="display: flex">
                                <i class="pi pi-shop" style="margin-top: 2px;color: #1F5FC0"></i><h5 style="color: #1F5FC0; margin-left: 5px;">Kliendi vaade</h5>
                            </div>
                            <p>
                                Tutvu kuidas hakkab e-poodi nägema sinu klient. Proovi läbi viia tellimise protsessi ning veendu, et teekond külastusest ostu sooritamiseni on sinu kliendi jaoks mugav. 
                            </p>

                            <ul class="check-list-wrap list-two-col py-3" style="margin-top: 20px;">
                                <div class="demoContainer" id="secBtn">
                                  <a href="#" class="d-flex align-items-center app-download-btn btn btn-outline-brand-03 btn-rounded">
                                    <i class="pi pi-chart-line"></i>
                                    <div class="download-text text-left orange">
                                      <small>Vaata e-poe demo</small>
                                      <h5 class="mb-0">Halduskeskkond</h5>
                                    </div>
                                  </a>
                                </div>
                                <div class="demoContainer">
                                  <a href="#" class="d-flex align-items-center app-download-btn btn btn-outline-brand-02 btn-rounded">
                                    <i class="pi pi-shop"></i>
                                    <div class="download-text text-left">
                                      <small>Vaata e-poe demo</small>
                                      <h5 class="mb-0">Kliendi vaade</h5>
                                    </div>
                                  </a>
                                </div>
                            </ul>
                            <svg width="121" id="removeBtnIllustration" height="93" viewBox="0 0 121 93" fill="none" style="position: absolute; margin-top: -0px; left: 59px; rotate: 30deg;" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.93604 64.122L65.842 32.621C68.327 31.22 72.637 31.443 75.471 33.119L111.041 54.154C113.877 55.827 114.157 58.321 111.674 59.721L55.768 91.225C53.283 92.625 48.971 92.402 46.138 90.725L10.57 69.691C7.73504 68.013 7.45304 65.523 9.93604 64.122Z" fill="#D4ECFF"/>
                              <path d="M54.958 75.391L118.949 39.328C120.216 38.616 120.82 37.651 120.852 36.636C120.855 36.636 120.882 36.658 120.882 36.658L120.956 29.532C120.956 29.532 118.706 31.377 117.384 32.458L77.513 8.87797C74.269 6.95997 69.332 6.70301 66.49 8.30601L5.32498 42.7719L0.646988 40.005L0.562988 47.198L0.590988 47.174C0.608988 48.407 1.47898 49.707 3.22198 50.741L43.935 74.818C47.18 76.734 52.114 76.992 54.958 75.391Z" fill="#CC8D2F"/>
                              <path d="M2.58004 37.112L66.571 1.05402C69.415 -0.548977 74.35 -0.29197 77.595 1.62403L118.307 25.701C121.552 27.622 121.875 30.473 119.033 32.077L55.039 68.135C52.198 69.739 47.262 69.481 44.018 67.561L3.30503 43.485C0.0610349 41.568 -0.263964 38.716 2.58004 37.112Z" fill="#F9A930"/>
                              <path d="M55.042 68.136L119.032 32.078C120.647 31.166 121.206 29.85 120.82 28.532C120.52 29.152 119.995 29.721 119.176 30.184L55.183 66.241C52.339 67.844 47.404 67.591 44.161 65.667L3.44803 41.594C2.04903 40.765 1.22803 39.766 0.935028 38.764C0.196028 40.287 0.996031 42.122 3.30403 43.486L44.017 67.562C47.262 69.485 52.197 69.739 55.042 68.136Z" fill="white"/>
                              <path d="M69.712 27.763C67.356 26.371 67.379 24.153 69.762 22.809C72.148 21.465 75.991 21.502 78.346 22.897C80.702 24.293 80.678 26.511 78.295 27.856C75.912 29.196 72.069 29.158 69.712 27.763ZM56.631 35.134C54.276 33.742 54.299 31.524 56.683 30.18C59.069 28.834 62.91 28.877 65.266 30.268C67.622 31.663 67.599 33.88 65.215 35.222C62.829 36.567 58.988 36.525 56.631 35.134ZM43.552 42.505C41.196 41.11 41.219 38.895 43.605 37.549C45.989 36.203 49.831 36.245 52.188 37.64C54.544 39.032 54.521 41.251 52.137 42.596C49.75 43.937 45.908 43.899 43.552 42.505Z" fill="white"/>
                            </svg>
                            <svg width="121" id="removeBtnIllustration" height="93" viewBox="0 0 121 93" fill="none" style="position: absolute; margin-top: -0px; left: 270px; rotate: 30deg;" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.93604 64.122L65.842 32.621C68.327 31.22 72.637 31.443 75.471 33.119L111.041 54.154C113.877 55.827 114.157 58.321 111.674 59.721L55.768 91.225C53.283 92.625 48.971 92.402 46.138 90.725L10.57 69.691C7.73504 68.013 7.45304 65.523 9.93604 64.122Z" fill="#D4ECFF"/>
                              <path d="M54.958 75.391L118.949 39.328C120.216 38.616 120.82 37.651 120.852 36.636C120.855 36.636 120.882 36.658 120.882 36.658L120.956 29.532C120.956 29.532 118.706 31.377 117.384 32.458L77.513 8.87797C74.269 6.95997 69.332 6.70301 66.49 8.30601L5.32498 42.7719L0.646988 40.005L0.562988 47.198L0.590988 47.174C0.608988 48.407 1.47898 49.707 3.22198 50.741L43.935 74.818C47.18 76.734 52.114 76.992 54.958 75.391Z" fill="#2F6ECC"/>
                              <path d="M2.58004 37.112L66.571 1.05402C69.415 -0.548977 74.35 -0.29197 77.595 1.62403L118.307 25.701C121.552 27.622 121.875 30.473 119.033 32.077L55.039 68.135C52.198 69.739 47.262 69.481 44.018 67.561L3.30503 43.485C0.0610349 41.568 -0.263964 38.716 2.58004 37.112Z" fill="#308CF9"/>
                              <path d="M55.042 68.136L119.032 32.078C120.647 31.166 121.206 29.85 120.82 28.532C120.52 29.152 119.995 29.721 119.176 30.184L55.183 66.241C52.339 67.844 47.404 67.591 44.161 65.667L3.44803 41.594C2.04903 40.765 1.22803 39.766 0.935028 38.764C0.196028 40.287 0.996031 42.122 3.30403 43.486L44.017 67.562C47.262 69.485 52.197 69.739 55.042 68.136Z" fill="white"/>
                              <path d="M69.712 27.763C67.356 26.371 67.379 24.153 69.762 22.809C72.148 21.465 75.991 21.502 78.346 22.897C80.702 24.293 80.678 26.511 78.295 27.856C75.912 29.196 72.069 29.158 69.712 27.763ZM56.631 35.134C54.276 33.742 54.299 31.524 56.683 30.18C59.069 28.834 62.91 28.877 65.266 30.268C67.622 31.663 67.599 33.88 65.215 35.222C62.829 36.567 58.988 36.525 56.631 35.134ZM43.552 42.505C41.196 41.11 41.219 38.895 43.605 37.549C45.989 36.203 49.831 36.245 52.188 37.64C54.544 39.032 54.521 41.251 52.137 42.596C49.75 43.937 45.908 43.899 43.552 42.505Z" fill="white"/>
                            </svg>

                            

                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6" id="illustrationImg" style="margin-bottom: -100px; margin-top: 15px; z-index: 10;">
                      <img src="../../assets/storium/illustration.svg" alt="hero" class="img-fluid" style="height: 500px; margin-top: -22px; margin-left: 100px">

                    </div>
                    <img 
                        class="backgroundImgTwo"
                        :src="require('@/assets/illustrations/backgroundGrid.svg')" 
                        alt=""
                    >
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Offerings',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        },
        data: function(){
            return {
                showModal: false
            }
        },
        methods: {
            showYouTubeModal: function(e) {
                e.preventDefault();
                this.showModal = true;
            }
        }
    }
</script>
<style scoped>
.demoContainer i {
  font-size: 24px;
  margin-right: 10px;
  margin-left: -3px;
}
#secBtn {
    margin-right: 20px;
}
.download-text .text-left .orange {
  color: #F9A930;
}
.backgroundImgTwo {
    position: absolute;
    right: -50px;
    width: 100%;
    opacity: .080;
}

@media (max-width: 991px) {
    #illustrationImg {
        display: none;
    }
    #about {
        padding-top: 70px;
        padding-bottom: 100px;
    }
}
@media (max-width: 415px) {
    #about {
        padding-bottom: 0px;
    }
    #removeBtnIllustration {
        display: none;
    }
    #secBtn {
        margin-right: 10px;
        margin-bottom: 15px;
    }
}
</style>