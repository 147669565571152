<template>
    <div class="column">
      <div class="buttons">
        <div class="button" style="border: 1px solid #16A34A"><i class="pi pi-check-circle" style="color: #16A34A"></i><p class="btnText" style="color: #16A34A">Kinnita tellimused</p> <div class="badge" style="background-color: #16A34A;"><p>2</p></div></div>
        <div class="button" id="removeBtnTwo" style="border: 1px solid #DC2626"><i class="pi pi-times-circle" style="color: #DC2626"></i><p class="btnText" style="color: #DC2626">Lükka tagasi</p> <div class="badge" style="background-color: #DC2626;"><p>2</p></div></div>
        <div class="button" id="removeBtn" style="border: 1px solid steelblue"><i class="pi pi-info-circle" style="color: steelblue"></i><p class="btnText" style="color: steelblue">Arved</p> <div class="badge" style="background-color: steelblue;"><p>2</p></div></div>
      </div>
      <!-- <div class="table">
        <div class="indexRow">
          <span style="width: 5%"></span>
          <span>Klient</span>
          <span>Väärtus</span>
          <span>Tarnemeetod</span>
          <span>Staatus</span>
        </div>
        <div class="singleRow">
          <div class="singleColumn" style="width: 5%">
            <i class="pi pi-check-circle"></i>
          </div>
          <div class="singleColumn">
            <p>Ken Tamm</p>
          </div>

          <div class="singleColumn">
            <p>310.90 €</p>
          </div>

          <div class="singleColumn">
            <p>Pakiautomaat</p>
          </div>

          <div class="singleColumn">
            <p>Makstud</p>
          </div>
        </div>
        <div class="singleRow">
          <div class="singleColumn" style="width: 5%">
            <i class="pi pi-check-circle"></i>
          </div>
          <div class="singleColumn">
            <p>Liis Kask</p>
          </div>

          <div class="singleColumn">
            <p>24.40 €</p>
          </div>

          <div class="singleColumn">
            <p>Pakikorje</p>
          </div>

          <div class="singleColumn">
            <p>Makstud</p>
          </div>
        </div>
      </div> -->
    </div>
</template>

<script>

export default {
    name: 'orderTable',
    components: {
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },

    created() {

    }
}
</script>
<style scoped>
.column {
  position: relative;
}
.buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.2);
          box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.2);
  background: #E3ECFB;
  padding: 12px 28px 10px 28px;
  border-radius: 100px;
}
.buttons p {
  margin: 0;
}
.badge {
  border-radius: 100%;
  height: 20px;
  width: 20px;
  background: white;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
}
.badge p {
  margin-top: 1px;
  color: rgb(255, 255, 255);
}
.buttons i {
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 7px;
  font-size: 18px;
}
.button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 7px;
  background: white;
  opacity: .6;
}
.indexRow {
  margin-top: 10px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid rgb(230,230,230);
  -webkit-box-shadow: 0 0 2.1875rem 0 rgba(119, 119, 120, 0.13);
          box-shadow: 0 0 2.1875rem 0 rgba(119, 119, 120, 0.13);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}
.indexRow span {
  width: 25%;
}
.indexRow span:last-child {
  text-align: end;
}
.singleRow {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #696969;
}
.singleColumn {
  width: 25%;
}
.singleColumn:last-child {
  text-align: end;
}
.singleColumn i {
  color: green;
  margin-top: 6px;
}

@media (max-width: 575px) {
    #removeBtn {
        display: none;
    }
}
@media (max-width: 500px) {
    #removeBtnTwo {
        display: none;
    }
    .button {
        width: 100%;
        text-align: center;
    }
    .btnText {
        width: calc(100% - 80px);
        padding-top: 2px;
        text-align: center;
    }
    .badge {
        margin-left: auto;
    }
    .badge p {
        margin-top: 2px;
    }
}
</style>