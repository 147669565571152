<template>
    <section id="process" class="work-process-section position-relative" style="overflow: hidden;" v-bind:class="{  'gray-light-bg' : isGray, 'pb-100': paddingBottom, 'pt-100': paddingTop   }">
        <div class="container">
            <img 
                class="backgroundImgTwo"
                style="position: absolute;top: 50px; right: 0px; left:0px; margin: auto; width: 90%; opacity: .02;"
                :src="require('@/assets/illustrations/backgroundGridThree.svg')" 
                alt=""
            >
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading text-center mb-5">
                        <h2>Kuidas Storium toimib?</h2>
                        <!-- <p>Teie koduleht, meie e-poe lahendus. Meie hoiustame teie tooteid, tellimusi ja viime läbi makseid. Modereerime teie e-poodi ning aitame aktiivselt külastajate arvu ja müüki suurendada.</p> -->
                        <p>Personaalne e-pood, mis jookseb teie domeeni, kuid meie serveri peal. Mis tähendab, et teie ei pea muretsema serverikulude ega tehnilise toimise pärast.</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center justify-content-md-center justify-content-sm-center">
                <div class="col-md-12 col-lg-6">
                    <div class="work-process-wrap">
                        <div class="process-single-item">
                            <div class="process-icon-item left-shape">
                            <div class="d-flex align-items-center">
                              <div class="process-icon mr-4">
                                <i class="fas fa-cash-register color-primary"></i>
                                
                              </div>
                              <div class="process-content text-left">
                                <h5>Rohkem kui lihtsalt teenus</h5>
                                <p>Koostöö, kus meie haldame teie poodi ja teie haldate enda tooteid ning tellimusi. Loome teile vajalikud tööriistad, ühendame maksesüsteemid ja seadistame domeeni. Juriidilistest dokumentidest tehniliste protokollideni, meie hoolitseme kõige eest, mis ühe e-poe toimimiseks vajalik.
                                </p>
                              </div>
                            </div>
                            <svg x="0px" y="0px" width="312px" height="130px">
                                <path
                                    class="dashed1"
                                    fill="none"
                                    stroke="rgb(95, 93, 93)"
                                    stroke-width="1"
                                    stroke-dasharray="1300"
                                    stroke-dashoffset="0"
                                    d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                                ></path>
                                <path
                                    class="dashed2"
                                    fill="none"
                                    stroke="#ffffff"
                                    stroke-width="2"
                                    stroke-dasharray="6"
                                    stroke-dashoffset="1300"
                                    d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                                ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="process-single-item">
                            <div class="process-icon-item right-shape">
                                <div class="d-flex align-items-center">
                                    <div class="process-icon ml-4" style="margin-top: -34px;">
                                        <i class="fas fa-store-alt color-primary"></i>
                                    </div>
                                    <div class="process-content text-right">
                                        <h5>Tugitala Sinu e-poele</h5>
                                        <p>Pakume tuge ja abi igas etapis: toodete lisamisest, piltide kuni tekstide regideerimiseni. Meie huvides on, et sinu pood müüks rohkem. Modereerime poodi ning aitame külastajaid ja müügiarvu kasvatada.</p>
                                    </div>
                                </div>
                                <svg x="0px" y="0px" width="312px" height="130px">
                                    <path
                                        class="dashed1"
                                        fill="none"
                                        stroke="rgb(95, 93, 93)"
                                        stroke-width="1"
                                        stroke-dasharray="1300"
                                        stroke-dashoffset="0"
                                        d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                                    ></path>
                                    <path
                                        class="dashed2"
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-dasharray="6"
                                        stroke-dashoffset="1300"
                                        d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div class="process-single-item">
                            <div class="process-icon-item left-shape mb-0">
                                <div class="d-flex align-items-center">
                                    <div class="process-icon mr-4">
                                        <i class="fas fa-wallet color-primary"></i>
                                    </div>
                                    <div class="process-content text-left">
                                        <h5>Taskukohane lahendus</h5>
                                        <p>E-poe ja halduslahenduse ülesseadmise eest on ühekordne tasu + kokkuleppeliselt 1-5% iga sooritatud müügi pealt teenustasu. Lõplik maksumus oleneb ärimudelist, võta meiega ühendust ning teeme Sinu vajadustest lähtuva hinnapakkumise.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6" id="rightSideIllustrations">
                    <div class="img-wrap">
                       
                      <div class="stars" style="display: flex; margin-left: -13px; margin-bottom: 15px; margin-top: -40px; opacity: .25;">
                        <svg width="40px" style="margin-left: 15px" viewBox="0 0 492 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M255.059 26.3027L307.473 173.751L463.753 177.879L491.591 178.647L469.512 195.542L345.391 290.865L389.836 440.906L397.708 467.496L374.765 451.849L245.748 363.246L116.826 451.849L93.8833 467.592L101.755 440.906L146.105 290.769L22.0789 195.542L0 178.647L27.8386 177.879L184.311 173.655L236.532 26.3027L245.844 0L255.059 26.3027Z" fill="#FAD348"/>
                          <path d="M255.312 26.1107L307.725 173.559L464.005 177.687L491.844 178.455L469.765 195.35L345.643 290.673L390.089 440.714L397.96 467.304L375.017 451.657L246 363.054V0L255.312 26.1107Z" fill="#FAD348" fill-opacity="0.8"/>
                        </svg>
                        <svg width="40px" style="margin-left: 15px" viewBox="0 0 492 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M255.059 26.3027L307.473 173.751L463.753 177.879L491.591 178.647L469.512 195.542L345.391 290.865L389.836 440.906L397.708 467.496L374.765 451.849L245.748 363.246L116.826 451.849L93.8833 467.592L101.755 440.906L146.105 290.769L22.0789 195.542L0 178.647L27.8386 177.879L184.311 173.655L236.532 26.3027L245.844 0L255.059 26.3027Z" fill="#FAD348"/>
                          <path d="M255.312 26.1107L307.725 173.559L464.005 177.687L491.844 178.455L469.765 195.35L345.643 290.673L390.089 440.714L397.96 467.304L375.017 451.657L246 363.054V0L255.312 26.1107Z" fill="#FAD348" fill-opacity="0.8"/>
                        </svg>
                        <svg width="40px" style="margin-left: 15px" viewBox="0 0 492 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M255.059 26.3027L307.473 173.751L463.753 177.879L491.591 178.647L469.512 195.542L345.391 290.865L389.836 440.906L397.708 467.496L374.765 451.849L245.748 363.246L116.826 451.849L93.8833 467.592L101.755 440.906L146.105 290.769L22.0789 195.542L0 178.647L27.8386 177.879L184.311 173.655L236.532 26.3027L245.844 0L255.059 26.3027Z" fill="#FAD348"/>
                          <path d="M255.312 26.1107L307.725 173.559L464.005 177.687L491.844 178.455L469.765 195.35L345.643 290.673L390.089 440.714L397.96 467.304L375.017 451.657L246 363.054V0L255.312 26.1107Z" fill="#FAD348" fill-opacity="0.8"/>
                        </svg>
                        <svg width="40px" style="margin-left: 15px" viewBox="0 0 492 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M255.059 26.3027L307.473 173.751L463.753 177.879L491.591 178.647L469.512 195.542L345.391 290.865L389.836 440.906L397.708 467.496L374.765 451.849L245.748 363.246L116.826 451.849L93.8833 467.592L101.755 440.906L146.105 290.769L22.0789 195.542L0 178.647L27.8386 177.879L184.311 173.655L236.532 26.3027L245.844 0L255.059 26.3027Z" fill="#FAD348"/>
                          <path d="M255.312 26.1107L307.725 173.559L464.005 177.687L491.844 178.455L469.765 195.35L345.643 290.673L390.089 440.714L397.96 467.304L375.017 451.657L246 363.054V0L255.312 26.1107Z" fill="#FAD348" fill-opacity="0.8"/>
                        </svg>
                        <svg width="40px" style="margin-left: 15px" viewBox="0 0 492 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M255.059 26.3027L307.473 173.751L463.753 177.879L491.591 178.647L469.512 195.542L345.391 290.865L389.836 440.906L397.708 467.496L374.765 451.849L245.748 363.246L116.826 451.849L93.8833 467.592L101.755 440.906L146.105 290.769L22.0789 195.542L0 178.647L27.8386 177.879L184.311 173.655L236.532 26.3027L245.844 0L255.059 26.3027Z" fill="#FAD348"/>
                          <path d="M255.312 26.1107L307.725 173.559L464.005 177.687L491.844 178.455L469.765 195.35L345.643 290.673L390.089 440.714L397.96 467.304L375.017 451.657L246 363.054V0L255.312 26.1107Z" fill="#FAD348" fill-opacity="0.8"/>
                        </svg>
                        <svg width="40px" style="margin-left: 15px" viewBox="0 0 492 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M255.059 26.3027L307.473 173.751L463.753 177.879L491.591 178.647L469.512 195.542L345.391 290.865L389.836 440.906L397.708 467.496L374.765 451.849L245.748 363.246L116.826 451.849L93.8833 467.592L101.755 440.906L146.105 290.769L22.0789 195.542L0 178.647L27.8386 177.879L184.311 173.655L236.532 26.3027L245.844 0L255.059 26.3027Z" fill="#FAD348"/>
                          <path d="M255.312 26.1107L307.725 173.559L464.005 177.687L491.844 178.455L469.765 195.35L345.643 290.673L390.089 440.714L397.96 467.304L375.017 451.657L246 363.054V0L255.312 26.1107Z" fill="#FAD348" fill-opacity="0.8"/>
                        </svg>

                      </div>
                        <productCard style=" z-index: 10; margin-right: 30px" />
                        <img 
                          class="shapeOne"
                          style=" z-index: 0; position: absolute; bottom: -50px; 
                          right: -20px;"
                          :src="require('../../assets/storium/shapes/cta-3-shape-1-1.png')"
                          alt=""
                        > 
            

                        <!-- <listingCard/> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <img 
          class="shapeOne"
          style="position: absolute; top: 0; right: 0; z-index: 0;"
          :src="require('../../../assets/storium/shapes/cta-3-shape-1-2.png')"
          alt=""
        >  -->
        
    </section>
</template>

<script>
import productCard from '../../components/storium/productCard.vue';
// import listingCard from '../../components/storium/listingCard.vue';
    export default {
        name: "WorkSection",
        components: {
          productCard,
          // listingCard
        },
        props: {
            isGray: {
                type: Boolean,
                default: false
            },
            paddingTop: {
                type: Boolean,
                default: true
            },
            paddingBottom: {
                type: Boolean,
                default: true
            }
        },
    };
</script>
<style scoped>
.img-wrap {
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.openSign {
  position: relative;
  z-index: 10;
  width: 340px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #E3ECFB;
  border-radius: 100px;
  padding: 8px 15px 5px 15px;
  white-space: nowrap;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.2);
          box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.2);
}
.openSign p {
  color: #4986e2d8;
  font-size: 20px;
  font-weight: bold;
  font-family: 'quicksand', sans-serif;
}
.openSign p:first-child {
  margin: 0;margin-left: auto;
}
.openSign p:last-child {
  margin: 0;margin-right: auto;
}
.openSign i {
  font-size: 32px;
  margin-top: 0px;
  margin-right: 15px;
  margin-left: 15px;
  color: #4986e2d8;
}
@media (max-width: 991px) {
    #rightSideIllustrations {
        margin-top: 50px;
    }
}
</style>