<template>
    <header class="header">
        <nav class="navbar navbar-expand-lg fixed-top" v-bind:class="{ affix: hasAffix, 'custom-nav': coloredLogo, 'bg-transparent': !coloredLogo }">
            <div class="container">
                <a class="navbar-brand" v-scroll-to="'#bannerHome'">
                    <img 
                      :src="require('../../assets/storium/storium.svg')"
                      alt="logo"
                      width="139px"
                      class="img-fluid"
                      style="margin-top: -5px;"
                    />
                </a>
                <button class="navbar-toggler" type="button" @click="mobileNavClicked" v-bind:class="{ 'collapsed': collapsed }">
                    <span class="ti-menu"></span>
                </button>
                <div class="collapse navbar-collapse h-auto" v-bind:class="{ 'show': !collapsed }" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto menu">
                        <li>
                            <router-link to="" v-scroll-to="'#process'">Kuidas Storium toimib?</router-link>
                        </li>
                        <li>
                            <router-link to="" v-scroll-to="'#features'">Haldus</router-link>
                        </li>
                        <li>
                            <router-link to="" v-scroll-to="'#about'">Demo</router-link>
                        </li>
                        <!-- <li>
                            <router-link to="#pricing" v-scroll-to="'#pricing'">Hinnastus</router-link>
                        </li> -->
                        <li>
                            <router-link to="" v-scroll-to="'#contact'" style="padding: 22px 0;">Kontakteeru</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        props: {
            coloredLogo: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                windowTop: 0,
                collapsed: true
            }
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll)
        },
        methods: {
            onScroll: function() {
                this.windowTop = window.top.scrollY
            },
            mobileNavClicked: function() {
                this.collapsed = !this.collapsed;
            }
        },
        computed: {
            hasAffix: function () {
                return this.windowTop > 0;
            }
        }
    }
</script>
<style scoped>

</style>

