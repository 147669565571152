<template>
    <div>
        <nav-bar :colored-logo="true" />
        <div class="main">
            <banner />
            <PromoSmall />
            <work-process :is-gray="true" />
            <!-- <about /> -->
            <features />
            <offerings :is-gray="true"/>
            <!-- <screenshots :padding-top="false" /> -->
            <!-- <price /> -->
            <contact />

        </div>
    </div>
</template>

<script>
    import NavBar from "../views/storium/NavBar.vue";
    import Banner from "../views/storium/Banner.vue";
    import PromoSmall from "../views/storium/Promo";
    // import About from "../views/index-seven/About.vue";
    import Offerings from "../views/storium/Offerings.vue";
    import Features from "../views/storium/Features";
    // import Price from "../views/index-one/Price";
    // import Screenshots from "../views/index-one/Screenshots";
    import WorkProcess from "../views/storium/WorkProcess";
    import Contact from "../views/storium/Contact";


    export default {
        name: "storiumMain",
        components: {
            NavBar,
            Banner,
            PromoSmall,
            // About,
            Offerings,


            // Screenshots,
            Features,
            WorkProcess,
            // Price,

            Contact,
        },
    };
</script>