<template>
  <div>
    <div class="singleNotification">
        <i class="pi pi-send"></i>
        <div class="header">
            <div class="status">
                <p class="date">20. september, teisipäev (13:35)</p>
            </div>
            <div class="bodyWrapper">
                <p class="subject" style="font-size: 16px;">Saabunud on uus tellimus väärtuses 149.50€
                </p>
            </div>
        </div>
        <div class="designElement"></div>
    </div>
    <div class="singleNotification">
        <i class="pi pi-send"></i>
        <div class="header">
            <div class="status">
                <p class="date">23. september, reede (09:22)</p>
            </div>
            <div class="bodyWrapper">
                <p class="subject" style="font-size: 16px;">Tellimus väärtuses 149.50€ on viidud lõpule
                </p>
            </div>
        </div>
        <div class="designElement"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'singleNotification',
  components: {
  },
  data() {
      return {
      }
  },  
  methods: {
      

  },
  created() {

  }

}
</script>
<style scoped>
.singleNotification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px 0px 20px 20px;
  background: #E3ECFB;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  margin-bottom: 15px;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
}
.singleNotification:hover {
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.bodyWrapper {
  margin-top: -2px;
}
.header {
  position: relative;
  z-index: 1;
}
.functionBtns {
  position: absolute;
  top: 38px;
  right: 0;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}
.singleNotification:hover {
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}
.status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
}
.status p {
  font-family: 'dm sans', sans-serif;
  margin: 0;
  color: #4986e2b2;
  font-size: 14px;
}
.iconImg {
  width: 35px;
  margin-right: 20px;
  margin-top: 10px;
}
.bodyWrapper p {
  margin: 0;
  font-size: 16px;
  color: #4986e2b2;
  font-weight: 500;
  
}
.singleNotification i {
  position: relative;
  z-index: 1;
  font-size: 28px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  color: #b3c7e6;
  background: #ffffff;
  padding: 10px;
  border-radius: 100%;
}
.designElement {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60%;
  /* background: linear-gradient(to right, #fbfeff, #e4f0ff); */
}

@media (max-width: 575px) {
    
}
</style>