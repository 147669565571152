<template>
    <div class="listingViewCard">
        <div class="photoContainer">
            <div class="photoContainerGrayHidden"></div>
                <img 
                    class="productImage"
                    height="280px" 
                    width="100%" 
                    :src="require(`../../assets/storium/${image}.jpg`)"
                    alt=""
                    > 
                    
            <i class="pi pi-shopping-cart" id="addToCardIcon"></i>
            <div class="priceTag">
                <h3>{{price}} €</h3>
            </div>
        </div>
        <div class="titleContainer" >
            <h2>{{ title }}</h2>
        </div>
        <div class="shopNameContainer">
            <p>{{shop}}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'productCard',
    components: {
    },
    props: {
        listingData: {
            required: true
        },
        waitTime: {
            default: 1000
        },
        skeletonOnly: null,
        shopPage: {},
        index: {},
        title: null,
        shop: null,
        image: null,
        price: null
    },
    watch: {
        listingData: function() {
            this.coverImg()
        },
        skeletonOnly() {
            this.isLoading = this.skeletonOnly
        }
    },
    data() {
        return {
            errors: '',
            quantity: 1,
            error: false,
            notifArea: false,
            loaderActive: false,
            mobileActive: false,
            loadedAmount: 0,

            isLoading: true,
            thumbnailName: '',
            shopThumbnailName: ''
        } 
    },  
    methods: { 
        clickAway() {
            this.mobileActive = false
        },

        coverImg() {
            this.isLoading = true
            let waitTimeRaw = this.waitTime
            waitTimeRaw = this.index * 50 + waitTimeRaw

            if(process.env.NODE_ENV === "production") {
                var name = this.listingData.coverImg
                var nameShop = this.listingData.shopCoverImg
                const newName = name.split('.')
                const newNameShop = nameShop.split('.')
                this.thumbnailName = newName[0] + '.thumb.' + newName[1]
                this.shopThumbnailName = newNameShop[0] + '.thumb.' + newNameShop[1]

                setTimeout(() => {
                    this.isLoading = false
                }, waitTimeRaw)
            } else {
                this.thumbnailName = this.listingData.coverImg
                this.shopThumbnailName = this.listingData.shopCoverImg

                setTimeout(() => {
                    this.isLoading = false
                }, waitTimeRaw)
            }
        },

        openListing() {
            this.$emit('startLoading')
            this.$router.push('/kuulutus/'+this.listingData.idListing)
        },

        addOrderToLocalstorage() {
            const storageData = localStorage.getItem('guestOrder')
            let guestOrders = JSON.parse(storageData)

            if(!Array.isArray(guestOrders)) {
                guestOrders = []
            }

            const listingsData = {
                title: this.listingData.title, 
                img: this.listingData.coverImg,
                price: this.listingData.price, 
                idListing: this.listingData.idListing, 
                qty: this.quantity,
                attr: {dimensions: this.listingData.attr.dimensions, deliveryTime: this.listingData.deliveryTime}
            }

            const shopData = {
                shopName: this.listingData.shopName,
                shopCoverImg: this.listingData.shopCoverImg,
                idShop: this.listingData.idShop,
                listings: [listingsData]
            }

            guestOrders.forEach((e, i) => {
                // function checkIfShopOrderExists
                if(e.idShop == this.listingData.idShop) {
                    const orderListings = guestOrders[i].listings

                    orderListings.forEach((el, index) => {
                        // function appendQtyToExistentListingInOrder
                        if(el.idListing == this.listingData.idListing) {
                            orderListings[index].qty = orderListings[index].qty + this.quantity
                            this.$toast.add({ 
                                severity: 'warn', 
                                summary: 'Toode juba on tellimuses, kogust suurendati!', 
                                detail: '', 
                                life: 5000 
                            });
                        }
                    })
                    // function addListingToExistingOrder
                    if(!orderListings.find(e => e.idListing == this.listingData.idListing)) {
                        orderListings.push(listingsData)
                    }
                }
            })
            // function createNewOrder
            if(!guestOrders.find(e => e.idShop == this.listingData.idShop)) {
                guestOrders.push(shopData)
            }

            let string = JSON.stringify(guestOrders)
            localStorage.setItem('guestOrder', string)
        },


    },
    
    created() {
        this.coverImg()
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

.listingViewCard {
    position: relative;
}
.photoContainer {
    position: relative;
    overflow: hidden;
    width: 270px;
    height: 273px;
    margin-left: 0px;
    border-radius: 20px;
    background: white;
    text-align: center;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
}
.photoContainerGray {
    position: relative;
    overflow: hidden;
    width: 260px;
    height: 273px;
    margin-left: 0px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    z-index: 901;
    background: rgb(234,234,234);
    /* transition: .5s; */
}
.photoContainerGrayHidden {
    position: absolute;
    overflow: hidden;
    width: 260px;
    height: 273px;
    margin-left: 0px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    z-index: -10;
    background: rgba(234, 234, 234, 0);
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.productImage {
    position: relative;
    overflow: hidden;
    z-index: 10;
    margin-top: 0px;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.productImageBlur {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
    width: 260px;
    height: 240px;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-filter:blur(6px) opacity(.5);
            filter:blur(6px) opacity(.5);
    -webkit-transform: scale(1.02);
        -ms-transform: scale(1.02);
            transform: scale(1.02);
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.photoContainer:hover .productImage {
    -webkit-transform: scale(1.07);
        -ms-transform: scale(1.07);
            transform: scale(1.07);
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.titleContainer {
    z-index: 99;
    padding-bottom: 0px;
    padding-top: 8px;
    width: 100%;
}
.titleContainerShop {
    z-index: 99;
    padding-bottom: 0px;
    padding-top: 8px;
    width: 100%;
    bottom: 0px;
}
.titleContainerGray {
    z-index: 100;
    padding-bottom: 5px;
    padding-top: 10px;
    border-radius: 5px;
    margin-left: 3px;
    margin-top: 12px;
    width: 80%;
    background: rgb(234,234,234);
}
.titleContainerMobile {
    z-index: 99;
    padding-bottom: 30px;
    padding-top: 15px;
    width: 100%;
    bottom: 0px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 0));
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.9)), to(rgba(255, 255, 255, 0)));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 0));
}
h2 {
    text-align: start;
    margin: 0;
    width: 270px;
    max-height: 45px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1rem;
    color: rgb(40, 40, 40);
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-weight: bold;
    font-family: 'DM sans', sans-serif;
}
.titleContainer h2:hover {
    color: #64C3A2;
    cursor: pointer;
}
.shopNameContainer {
    opacity: 1;
    margin-top: 2px;
    position: relative;
    z-index: 100;
    color: rgb(160, 160, 160);
    /* -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.shopNameContainer p {
    margin: 0;
    font-size: .9rem;
    padding-left: 5px;
    text-align: start;
    cursor: pointer;
}
.shopNameContainer p {
    color: rgb(135, 135, 135);
}
.shopNameContainer p:hover {
    text-decoration: underline;
}
.shopNameContainerGray {
    z-index: 100;
    padding-bottom: 3px;
    padding-top: 8px;
    border-radius: 5px;
    margin-left: 3px;
    margin-top: 8px;
    width: 50%;
    background: rgb(234,234,234);
}
.priceTag {
    z-index: 101;
    padding-left: 13px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    background: rgba(41, 41, 41, 0.733);
    border-radius: 10px 0px 0px 0px;
}
.priceTag h3 {
    margin: 0;
    color: white;
    text-align: center;
    font-size: 1.2rem;
    font-family: 'DM sans', sans-serif;
    font-weight: 400;
}
.priceTag a {
    color: white;
}
#addToCardIcon {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 900;
    font-size: 25px;
    color: rgb(255, 255, 255);
    padding: 10px 20px 9px 11px;
    background: #0D89EC;
    border-radius: 40px 400px 40px 200px;
}
@media only screen and (max-width: 815px) {
    .titleContainer {
        bottom: 0;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .shopNameContainer {
        opacity: 1;
        bottom: 11px;
    }
}
@media only screen and (max-width: 430px) {
    .photoContainer {
        width: 100%;
    }
    .photoContainerGrayHidden {
        width: 100%;
    }
    .photoContainerGray {
        width: 100%;
    }
    .shopNameContainerGray {
        margin-bottom: 20px;
    }
    .productImageBlur {
        width: 100%;
    }
    .listingViewCard {
        width: 90%;
        margin: 0 auto;
    }
    h2 {
        width: 90%;
    }
}
</style>