<template>
    <div class="productCard">
        <img 
            class="listingPhoto"
            :class="{ active: hoveredIndex === index }"
            :src="require('../../assets/storium/productImage.jpg')"
            alt=""
        >
        <p class="smallCatTitle"> <i class="pi pi-sparkles" style="color: rgb(220,220,220); margin-right: 2px;"></i> Pagaritooted</p>
        <p class="title">Soojad kaneelirullid otse ahjust <br> 12 tükki pakis | 300g</p>

        <div class="dataWrapper">
            <div class="singleData">
                <!-- <Tag v-if="index == 2 && !singleListingData.acl.isAclCustom" icon="pi pi-check" severity="success" value="Avalik"></Tag>
                <Tag v-if="index != 2 && !singleListingData.acl.isAclCustom" icon="pi pi-lock" severity="info" value="Privaatne"></Tag>
                <Tag v-if="singleListingData.acl.isAclCustom" icon="pi pi-exclamation-triangle" severity="warn" value="Otsas"></Tag> -->
                <div class="manualTag"><i class="pi pi-check"></i><span>Avalik</span></div>
                <div>
                    <p style="font-size: 18px;">24.90 <i class="pi pi-euro"></i></p>
                </div>
            </div>
            <div class="singleData">
                <p class="dataKey" style="color: rgb(175,175,175)">Laoseis</p>
                <div>
                    <p>{{Math.floor(Math.random() * (144 - 2 + 1)) + 2}} ühikut <i class="pi pi-qrcode"></i></p>
                </div>
            </div>
            <div class="singleData">
                <p class="dataKey" style="color: rgb(175,175,175)">Tarneaeg</p>
                <div>
                    <p>{{Math.floor(Math.random() * (14 - 2 + 1)) + 2}} päeva <i class="pi pi-send"></i></p>
                </div>
            </div>
        </div>


        <div class="functionBtns">
            <div class="wrapper">

                <button 
                  style="border: 1px solid gray"
                >
                  <i class="pi pi-eye" style="color: black"></i>  
                </button>
                <button style="border: 1px solid steelblue"
                ><i class="pi pi-pen-to-square" style="color: steelblue"></i>  </button>
                <button style="border: 1px solid orange"
                ><i class="pi pi-lock" style="color: orange"></i>  </button>
                <button style="border: 1px solid red"
                ><i class="pi pi-trash" style="color: red"></i>  </button>
            </div>
        </div>
    </div>
</template>

<script>
// import Tag from 'primevue/tag';
// import Button from 'primevue/button'

export default {
    name: 'productCard',
    components: {
        // Tag,
        // Button,
    },
    computed: {
        isRounded() {
            return this.hoveredIndex !== this.index;
        },
    },
    watch: {
        singleListingData() {
            setTimeout(() => {
                this.activeIndex = null
            }, 2000)
        }
    },
    props: {
        singleListingData: {
            required: false
        },
        index: {
            required: false
        },
        currentTab: {
            required: false
        },
    },
    data() {
        return {
            loader: false,
            activeIndex: null,
            hoveredIndex: null,
        }
    },

    methods: {
        showButtons() {
            this.hoveredIndex = this.index
        },

        hideButtons() {
            this.hoveredIndex = null
        },

        patchListingStatus(method, idListing) {
            this.activeIndex = this.index
            if(method == 'hide' || method == 'reveal') {
                this.$emit('patchListingAccess', method, idListing)
            }
            if(method == 'delete') {
                this.$emit('deleteListing', idListing)
            }
        },

        isRequestForChangeMade(listing) {
            if(listing.acl.isAclCustom) {
                if(Object.keys(listing.acl.rolePermissions).some(key => key === 'grant')) {
                    return true
                }
            }
            return false
        },

        onClick(name) {
            if(name == 'Vaata') {
                this.$router.push('/kuulutus/'+ this.singleListingData.idListing)
            }
            if(name == 'Määra privaatseks') {
                this.makePrivate(this.singleListingData.idListing)
            }
            if(name == 'Määra avalikuks') {
                this.makePublic(this.singleListingData.idListing)
            }
            if(name == 'Muuda') {
                this.$router.push('/muuda/kuulutus/'+ this.singleListingData.idListing)
            }
            if(name == 'Kustuta') {
                this.deleteListing(this.singleListingData.idListing)
            }
            if(name == 'Vaata statistikat') {
                this.openStatisticsSheet()
            }
            if(name == 'Sulge statistika') {
                this.statisticsSheet = false
            }
            if(name == 'Jaga linki') {
                this.shareLinkContainerActive = true
            }
        },

        makePrivate(idListing) {
            this.$emit('makeLoaderActive')
            fetch(this.$fetchIp+'/v2/listing/access/'+idListing+'?operation=hide', {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.status === 'success') {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        title: 'Kuulutus lisatud privaatsete alla!',
                    });
                    this.$emit('fetchListings')
                    this.optionsMenuActive = false
                }
            })
        },

        makePublic(idListing) {
            this.$emit('makeLoaderActive')
            fetch(this.$fetchIp+'/v2/listing/access/'+idListing+'?operation=reveal', {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.status === 'success') {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        title: 'Kuulutus lisatud aktiivsete alla!',
                    });
                    this.$emit('fetchListings')
                    this.optionsMenuActive = false
                }
                if(res.message === 'Access denied.') {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        title: 'Kuulutus vajab muutmist ja ei ole võimalik aktiveerida enne kui admin on muutused kinnitanud!',
                    });
                    this.$emit('fetchListings')
                    this.optionsMenuActive = false
                }
                
            })
        },

        deleteListing(idListing) {
            this.$fire({
                title: 'Oled kindel, et tahad kuulutuse kustutada?',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Kinnita!',
                cancelButtonText: 'Tühista'
            }).then((res) => {
                if (res.value) {
                    this.$emit('makeLoaderActive')
                    fetch(this.$fetchIp+'/v2/listing/'+idListing, {
                        method: 'DELETE',
                        headers: {
                            'Content-type':'application/json'
                        },
                        credentials: 'include',
                    })
                    .then((res) => res.json()) 
                    .then((res) => {
                        if(res.status === 'success') {
                            this.$notify({
                                group: 'foo',
                                type: 'warn',
                                title: 'Kuulutus kustutatud!',
                            });
                            this.$emit('fetchListings')
                            this.optionsMenuActive = false
                        }
                    })
                }
            })
        },

    }
       
}
</script>
<style>
.functionBtns .p-button-label {
    width: 90px;
}
</style>
<style scoped>

.productCard {
    position: relative;
    width: 325px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 0px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
    border-radius: 20px;
    background: white;
    overflow: hidden;
}
.listingPhoto {
    width: 240px;
    height: 180px;
    border-radius: 15px;
    margin-top: 25px;
    margin-left: 20px;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    border: 1px solid rgb(230,230,230);
}
.listingPhoto.active {
    width: 140px;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
}
.title {
    margin: 0;
    margin-top: 28px;
    margin-bottom: 25px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
    height: 40px;
    font-family: 'DM sans', sans-serif;
    overflow-wrap: break-word;
    color: black;
}
.smallCatTitle {
    position: absolute;
    margin-left: 20px;
    margin-top: 4px;
    font-size: 13px;
    color: rgb(175,175,175);
    text-transform: capitalize;
}
.priceWrapper {
    margin-top: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.priceContainer {
    width: 90px;
    height: 32px;
    padding-top: 3px;
    text-align: center;
    background: #2ECC77;
    color: white;
    border-radius: 5px;
    font-size: 22px;
    font-family: 'DM sans', sans-serif;
}
.discount p {
    margin: 0;
    font-size: 14px;
    text-align: center;
    color: #E35B5B;
}
.dataWrapper {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 18px;
    padding-top: 5px;
    padding-bottom: 10px;
    background: #F6F8F9;
    border-top: 1px solid rgb(232, 237, 240);
}
.singleData {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgb(240,240,240);
}
.singleData:last-child {
    border: none;
}
.singleData p {
    margin: 0;
}
.dataKey {
    font-size: 14px;
    color: rgb(148, 148, 148);
}
.singleData img {
    width: 22px;
    margin-top: -1px;
    margin-left: 10px;
    opacity: .5;
}
.singleData i {
    color: rgb(200,200,200);
    margin-left: 5px;
}
.singleData p {
    color: rgb(50, 50, 50);
}
.manualTag {
    padding: 2px 10px 0px 10px;
    background: #DCFCE7;
    border-radius: 5px;
}
.manualTag i {
  margin-right: 5px;
  margin-left: 0px;
  color: rgb(21, 128, 61);
}
.manualTag span {
  color: rgb(21, 128, 61);
}
.functionBtns {
    position: absolute;
    top: 0px;
    right: 12px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    opacity: 1;
}
.functionBtns.active {
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    opacity: 1;
}
.functionBtns span {
    position: absolute;
    width: 180px;
    top: 18px;
    right: -190px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    font-family: 'Quicksand', sans-serif;
    color: rgb(50,50,50);
    font-weight: bold;
}
.singleBtn {
    position: relative;
    height: 35px;
    width: 37px;
    margin-bottom: 7px;
    border-radius: 9px 0 0 9px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}
.wrapper {
    margin-top: 25px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 7px;
}
.singleBtn p {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 38px;
    right: 0;
    margin: auto;
    height: 21px;
    width: 190px;
    color: white;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
}
.singleBtn:last-child {
    margin-bottom: 0;
}
.singleBtn img {
    margin-top: 5px;
    margin-left: 6px;
    width: 25px;
    height: 25px;
}
.functionBtns.active .singleBtn {
    width: 165px;
}
.functionBtns.active span {
    right: 0;
}
.wrapper button {
  background: white;
  border: 1px solid silver;
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
@media (max-width: 1170px) {

}

@media (max-width: 700px) {
    
}
</style>
